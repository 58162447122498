document.addEventListener("DOMContentLoaded", function () {
  const reviewSlider = document.querySelector(".review-slider");
  const demoSlider = document.querySelector(".demo-slider");

  if (!reviewSlider && !demoSlider) return;

  if (reviewSlider) {
    new Swiper(".review-slider", {
      direction: "horizontal",
      loop: true,
      slidesPerView: 1,
      spaceBetween: 5,
      breakpoints: {
        480: { slidesPerView: 1.2 },
        831: { slidesPerView: 2 },
      },
      navigation: {
        nextEl: ".pastel-landing-next",
        prevEl: ".pastel-landing-prev",
      },
    });
  }

  if (demoSlider) {
    new Swiper(".demo-slider", {
      direction: "horizontal",
      loop: true,
      slidesPerView: 1,
      draggable: false,
      touchRatio: 0,
      spaceBetween: 5,
      navigation: {
        nextEl: ".pastel-demo-next",
        prevEl: ".pastel-demo-prev",
      },
    });
  }
});
